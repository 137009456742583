import { KIND_141, KIND_CANONICAL, KIND_EIP155, KIND_ZKSYNC } from '@/config/constants'
import type { ContractNetworkConfig, ContractNetworksConfig } from '@safe-global/protocol-kit'
import { type SafeVersion } from '@safe-global/safe-core-sdk-types'
import {
  type DeploymentFilter,
  type SingletonDeployment,
  getSafeL2SingletonDeployment as getSafeL2SingletonDeploymentSafe,
  getCompatibilityFallbackHandlerDeployment as getCompatibilityFallbackHandlerDeploymentSafe,
  getProxyFactoryDeployment as getProxyFactoryDeploymentSafe,
  getSafeSingletonDeployment as getSafeSingletonDeploymentSafe,
} from '@safe-global/safe-deployments'

const contracts = {
  canonical: {
    createCallAddress: '0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4',
    fallbackHandlerAddress: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
    multiSendAddress: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
    multiSendCallOnlyAddress: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
    safeSingletonAddress: '0x3E5c63644E683549055b9Be8653de26E0B4CD36E',
    safeProxyFactoryAddress: '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2',
    signMessageLibAddress: '0xA65387F16B013cf2Af4605Ad8aA5ec25a2cbA3a2',
    simulateTxAccessorAddress: '0x59AD6735bCd8152B84860Cb256dD9e96b85F69Da',
  } as ContractNetworkConfig,
  eip155: {
    createCallAddress: '0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d',
    fallbackHandlerAddress: '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
    multiSendAddress: '0x998739BFdAAdde7C933B942a68053933098f9EDa',
    multiSendCallOnlyAddress: '0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B',
    safeSingletonAddress: '0xfb1bffC9d739B8D520DaF37dF666da4C687191EA',
    safeProxyFactoryAddress: '0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC',
    signMessageLibAddress: '0x98FFBBF51bb33A056B08ddf711f289936AafF717',
    simulateTxAccessorAddress: '0x727a77a074D1E6c4530e814F89E618a3298FC044',
  } as ContractNetworkConfig,
  zksync: {
    createCallAddress: '0xcB8e5E438c5c2b45FbE17B02Ca9aF91509a8ad56',
    fallbackHandlerAddress: '0x2f870a80647BbC554F3a0EBD093f11B4d2a7492A',
    multiSendAddress: '0x0dFcccB95225ffB03c6FBB2559B530C2B7C8A912',
    multiSendCallOnlyAddress: '0xf220D3b4DFb23C4ade8C88E526C1353AbAcbC38F',
    safeSingletonAddress: '0x1727c2c531cf966f902E5927b98490fDFb3b2b70',
    safeProxyFactoryAddress: '0xDAec33641865E4651fB43181C6DB6f7232Ee91c2',
    signMessageLibAddress: '0x357147caf9C0cCa67DfA0CF5369318d8193c8407',
    simulateTxAccessorAddress: '0x4191E2e12E8BC5002424CE0c51f9947b02675a44',
  } as ContractNetworkConfig,
}

const contracts_141 = {
  canonical: {
    createCallAddress: '0x9b35Af71d77eaf8d7e40252370304687390A1A52',
    fallbackHandlerAddress: '0xfd0732Dc9E303f09fCEf3a7388Ad10A83459Ec99',
    multiSendAddress: '0x38869bf66a61cF6bDB996A6aE40D5853Fd43B526',
    multiSendCallOnlyAddress: '0x9641d764fc13c8B624c04430C7356C1C7C8102e2',
    safeSingletonAddress: '0x29fcB43b46531BcA003ddC8FCB67FFE91900C762',
    safeProxyFactoryAddress: '0x4e1DCf7AD4e460CfD30791CCC4F9c8a4f820ec67',
    signMessageLibAddress: '0xd53cd0aB83D845Ac265BE939c57F53AD838012c9',
    simulateTxAccessorAddress: '0x3d4BA2E0884aa488718476ca2FB8Efc291A46199',
  } as ContractNetworkConfig,
}

enum AdditionalContractName {
  safeSingletonAddress,
}

type AdditionalContractType = {
  [_key in SafeVersion]?: {
    canonical?: { [_contractName in keyof typeof AdditionalContractName]: string }
    eip155?: { [_contractName in keyof typeof AdditionalContractName]: string }
    zksync?: { [_contractName in keyof typeof AdditionalContractName]: string }
  }
}

const additional_contracts: AdditionalContractType = {
  '1.3.0': {
    canonical: {
      safeSingletonAddress: '0xd9Db270c1B5E3Bd161E8c8503c55cEABeE709552',
    },
    eip155: {
      safeSingletonAddress: '0x69f4D1788e39c87893C980c06EdF4b7f686e2938',
    },
    zksync: {
      safeSingletonAddress: '0xB00ce5CCcdEf57e539ddcEd01DF43a13855d9910',
    },
  },
  '1.4.1': {
    canonical: {
      safeSingletonAddress: '0x41675C099F32341bf84BFc5382aF534df5C7461a',
    },
  },
}

const buildContractNetworks_130 = () => {
  const customContracts = {} as ContractNetworksConfig

  KIND_CANONICAL.forEach((chainId) => (customContracts[chainId] = contracts.canonical))
  KIND_EIP155.forEach((chainId) => (customContracts[chainId] = contracts.eip155))
  KIND_ZKSYNC.forEach((chainId) => (customContracts[chainId] = contracts.zksync))

  return customContracts
}

const buildContractNetworks_141 = () => {
  const customContracts = {} as ContractNetworksConfig

  KIND_141.forEach((chainId) => (customContracts[chainId] = contracts_141.canonical))

  return customContracts
}

const buildAdditionalContractNetworks_130 = () => {
  const additionalContracts: { [chainId: string]: { [contractName: string]: string } } = {}

  KIND_CANONICAL.forEach((chainId) => (additionalContracts[chainId] = additional_contracts['1.3.0']?.canonical || {}))
  KIND_EIP155.forEach((chainId) => (additionalContracts[chainId] = additional_contracts['1.3.0']?.eip155 || {}))
  KIND_ZKSYNC.forEach((chainId) => (additionalContracts[chainId] = additional_contracts['1.3.0']?.zksync || {}))

  return additionalContracts
}

const buildAdditionalContractNetworks_141 = () => {
  const additionalContracts: { [chainId: string]: { [contractName: string]: string } } = {}

  KIND_141.forEach((chainId) => (additionalContracts[chainId] = additional_contracts['1.4.1']?.canonical || {}))

  return additionalContracts
}

const contractsNetwork_141 = buildContractNetworks_141()
const contractsNetwork_130 = buildContractNetworks_130()

const additionContractNetwork_130 = buildAdditionalContractNetworks_130()
const additionContractNetwork_141 = buildAdditionalContractNetworks_141()

export const contractsNetwork_141_Keys = Object.keys(contractsNetwork_141)
export const contractsNetwork_130_Keys = Object.keys(contractsNetwork_130)

export const getContractNetwork = (safeVersion: SafeVersion) => {
  if (safeVersion === '1.4.1') {
    return contractsNetwork_141
  }

  return contractsNetwork_130
}

export const getLatestFromCustomContracts = (chainId?: string): SafeVersion | null => {
  if (!chainId) return null

  if (KIND_141.includes(chainId)) return '1.4.1'
  if (Object.keys(contractsNetwork_130).includes(chainId)) return '1.3.0'

  return '1.3.0'
}

// Overrides

type SafeDeploymentMethod = (filter?: DeploymentFilter | undefined) => SingletonDeployment | undefined

export const overrideSafeDeploymentMethod = ({
  version,
  network,
  deploymentMethod,
  contract,
}: (DeploymentFilter | undefined) & {
  deploymentMethod: 'compatibilityFallback' | 'l2Singleton' | 'l1Singleton' | 'proxyFactory'
  contract: keyof ContractNetworkConfig
}) => {
  if (!version || !network) return

  const contractNetworks = getContractNetwork(version as SafeVersion)
  const contractNetworksKeys = Object.keys(contractNetworks)

  const isCustomNetwork = contractNetworksKeys.includes(network)

  // if is not custom network use defualt safe method
  if (!isCustomNetwork)
    switch (deploymentMethod) {
      case 'compatibilityFallback':
        return getCompatibilityFallbackHandlerDeploymentSafe({ version, network })
      case 'l2Singleton':
        return getSafeL2SingletonDeploymentSafe({ version, network })
      case 'l1Singleton':
        return getSafeSingletonDeploymentSafe({ version, network })
      case 'proxyFactory':
        return getProxyFactoryDeploymentSafe({ version, network })
      default:
        return
    }

  let address: string | undefined

  switch (deploymentMethod) {
    case 'l1Singleton':
      address = (
        version === '1.4.1'
          ? additionContractNetwork_141[network]?.safeSingletonAddress
          : additionContractNetwork_130[network]?.safeSingletonAddress
      ) as string

      break
    default:
      address = (
        version === '1.4.1' ? contracts_141.canonical[contract] : contractsNetwork_130[network]?.[contract]
      ) as string
      break
  }

  return {
    networkAddresses: {
      [network]: address,
    },
    released: true,
    version,
  } as SingletonDeployment
}

export const getCompatibilityFallbackHandlerDeployment: SafeDeploymentMethod = (filter) =>
  overrideSafeDeploymentMethod({
    ...filter,
    deploymentMethod: 'compatibilityFallback',
    contract: 'fallbackHandlerAddress',
  })

export const getSafeL2SingletonDeployment: SafeDeploymentMethod = (filter) =>
  overrideSafeDeploymentMethod({
    ...filter,
    deploymentMethod: 'l2Singleton',
    contract: 'safeSingletonAddress',
  })

export const getSafeSingletonDeployment: SafeDeploymentMethod = (filter) =>
  overrideSafeDeploymentMethod({
    ...filter,
    deploymentMethod: 'l1Singleton',
    contract: 'safeSingletonAddress',
  })

export const getProxyFactoryDeployment: SafeDeploymentMethod = (filter) =>
  overrideSafeDeploymentMethod({
    ...filter,
    deploymentMethod: 'proxyFactory',
    contract: 'safeProxyFactoryAddress',
  })
