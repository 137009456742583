import type { ReactElement } from 'react'
import { SvgIcon } from '@mui/material'
import GitHubIcon from '@mui/icons-material/GitHub'
import { useRouter } from 'next/router'
import css from './styles.module.css'
import { AppRoutes } from '@/config/routes'
import packageJson from '../../../../package.json'
import ExternalLink from '../ExternalLink'
import { COOKIE_URL, PRIVACY_URL, TERMS_URL } from '@/config/constants'

const footerPages = [
  AppRoutes.welcome.index,
  AppRoutes.settings.index,
  AppRoutes.imprint,
  AppRoutes.privacy,
  AppRoutes.cookie,
  AppRoutes.terms,
  AppRoutes.licenses,
]

const Footer = (): ReactElement | null => {
  const router = useRouter()

  if (!footerPages.some((path) => router.pathname.startsWith(path))) {
    return null
  }

  return (
    <footer className={css.container}>
      <ul>
        <li>
          <ExternalLink href={`${packageJson.homepage}/releases/tag/v${packageJson.version}`} noIcon>
            <SvgIcon component={GitHubIcon} inheritViewBox fontSize="inherit" sx={{ mr: 0.5 }} /> v{packageJson.version}
          </ExternalLink>
        </li>

        <li>
          <ExternalLink href="https://flare.network/">Flare</ExternalLink>
        </li>

        <li>
          <ExternalLink href={TERMS_URL}>Terms</ExternalLink>
        </li>

        <li>
          <ExternalLink href={PRIVACY_URL}>Privacy</ExternalLink>
        </li>

        <li>
          <ExternalLink href={COOKIE_URL}>Cookie policy</ExternalLink>
        </li>

        <li>
          Deployed by <ExternalLink href="https://www.palmeradao.xyz/deploy-safe-chain">Palmera</ExternalLink>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
